<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="studentAttendances"
      sort-by="name"
      class="elevation-1"
      :loading="isGettingData"
      loading-text="يتم جلب البيانات ...الرجاء الانتظار"
    >
      <template v-slot:body.prepend>
        <tr>
          <td >
       <v-text-field
              v-model="studentName"
              label="تحديد اسم لطالب "
              prepend-icon="mdi-seach"
              clearable
            ></v-text-field>
          </td>
              <td>
            <v-autocomplete
              ref="classes"
              v-model="classId"
              :items="classes"
              placeholder="جلب حسب شعبة محدد"
              required
              item-text="name"
              item-value="id"
              chips
            ></v-autocomplete>
          </td>
          <td>
            <v-menu
                                  ref="menuFromDate"
                               v-model="menuFromDate"
                              :close-on-content-click="false"
                             :nudge-right="40"
                             :return-value.sync="fromDate"
                             lazy
                             transition="scale-transition"
                            offset-y
                           full-width
                           min-width="290px" >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="fromDate"
            label="من تاريخ"
          
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="fromDate" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click="menuFromDate = false">Cancel</v-btn>
          <v-btn flat color="primary" @click="$refs.menuFromDate.save(fromDate)">OK</v-btn>
        </v-date-picker>
            </v-menu>
          </td>
           <td>
               <v-menu
                                  ref="menuToDate"
                               v-model="menuToDate"
                              :close-on-content-click="false"
                             :nudge-right="40"
                             :return-value.sync="toDate"
                             lazy
                             transition="scale-transition"
                            offset-y
                           full-width
                           min-width="290px" >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="toDate"
            label="إلى تاريخ"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="toDate" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click="menuToDate = false">Cancel</v-btn>
          <v-btn flat color="primary" @click="$refs.menuToDate.save(toDate)">OK</v-btn>
        </v-date-picker>
            </v-menu>
          </td>
          <td >
            <v-btn
              class="ma-2"
              outlined
              color="primary"
              @click="getStudentAttendances"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </td>
           <td>
            <export-excel
              class="btn btn-default outlined fab ma-2"
              :data="studentAttendances"
              :fields="exportHeaders"
              worksheet="My Worksheet"
              name="StudentAttendance.xls"
            >
              <v-btn outlined color="green"> تصدير البيانات</v-btn>
            </export-excel>
          </td>
        </tr>
      </template>

      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>سجل الدوام</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
   

         
        </v-toolbar>
      </template>

  

    </v-data-table>

  </div>
</template>

<script>
import { APIService } from "../../services/apiServices";

import debounce from "debounce";
const apiService = new APIService();
export default {
 
  data: () => ({
    
    valid: true,
    isGettingData: false,
    studentName: "",
    classId:0,
     classes: [],
    headers: [
      { text: "رقم البصمة", value: "enrollNum" },
       { text: "الطالب", value: "studentName" },
        { text: "اليوم ", value: "dayOfWeek" },
      { text: "التاريخ ", value: "logDate" },
      { text: "التوقيت", value: "logTime" },
       { text: "", value: "" },
  
    ],

    exportHeaders: {
      "enrollNum": "enrollNum",
      "studentName": "studentName",
      "dayOfWeek": "dayOfWeek",
      "logDate": "logDate",
       "logTime": "logTime",
    },
  
    defaultItem: {
      paymentDate: "",
      amount: 0,
      isPaid: false,
    },
    selectedStudentId: 0,
    menuFromDate:false,
    menuToDate:false,
    fromDate:"",
    toDate:"",
    students: [],
    search: "",
    studentAttendances: [],
    loadingStudents: false,
  }),

  computed: {
  
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    search(value) {
      if (!value) {
        return;
      }

      debounce(this.makeSearchStudent, 200)(value, this);
    },
  },

   created() {
    this.getStudentAttendances();
  this.getClasses();
  },

  methods: {
    makeSearchStudent: async (value, self) => {
      // Handle empty value
      if (!value) {
        this.students = [];
      }

      if (self.loadingStudents) {
        return;
      }

      await apiService
        .searchStudents(0, value)
        .then((response) => {
          self.students = response.students;
        })
        .catch((error) => {
          self.error = "Unknown Error. Please check details and try again.";
          alert(error);
          self.failed();
        })
        .finally(() => (self.loadingLearner = false));
    },

    getStudentAttendances() {
      this.isGettingData = true;
      const that = this;
      apiService
        .getStudentAttendances(this.studentName,this.classId,this.fromDate,this.toDate)
        .then((response) => {
          that.isGettingData = false;
          this.studentAttendances = response.studentAttendances;
        })
        .catch(() => {
          that.isGettingData = false;
        });
    },

    refreshData() {
      this.getStudentAttendances();
    },

        getClasses() {
      apiService
        .getClasses()
        .then((response) => {
          this.classes = response.classes;
        })
        .catch((error) => {
          this.error = "Unknown Error. Please check details and try again.";
          alert(error);
          this.failed();
        });
    },
   
  },
};
</script>
